.toptext {
  text-align: left;
  margin-bottom: 10px;
}

.left-align {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.weather-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.parameter-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #555;
}

.loading i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.parameter-box .parameter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.parameter-box .parameter .icon-and-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.parameter-box .parameter .icon-and-label i {
  margin-right: 0.5rem;
}

.parameter-box .parameter .data-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.parameter-box .parameter .data-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.parameter-box .parameter .data-value {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: fit-content;
}

.main-content {
  margin: 0 auto;
  padding: 2em;
  max-width: 1200px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.webcam-section {
  margin-top: 2em;
  text-align: left;
}

.webcam-section h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.webcam-image {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.webcam-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
