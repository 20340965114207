.schemas-container {
    padding: 2em;
  }
  
  .schemas-paper {
    padding: 16px;
    text-align: center;
  }
  
  .schemas-form-container {
    margin-top: 32px;
    transition: all 0.5s ease;
  }
  