.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1em;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-container h2 {
  text-align: center;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container label {
  margin-top: 10px;
}

.login-container .error {
  color: red;
  margin-top: 10px;
  text-align: center;
}
