.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  width: 100%; /* Legg til width: 100% */
  box-sizing: border-box; /* Inkluder padding i bredden */
}

.content {
  flex: 3;
  padding-right: 1em;
  box-sizing: border-box; /* Inkluder padding i bredden */
}



@media (max-width: 768px) {
  .content, .sidebar {
    flex: 100%;
    padding: 0;
    border-left: none;
  }

  .content {
    padding-right: 0;
  }
}
