.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em;
  position: relative;
  color: white;
  height: 10vh;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/public/img/header-img.jpg') center/cover no-repeat;
  filter: blur(5px);
  z-index: 1;
}

.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
  text-align: left; 
}

.logo-link {
  margin-right: 1em;
}

.logo {
  height: 80px; /* Økt høyden på logoen for bedre synlighet */
}

.title-link {
  text-decoration: none;
}

.title {
  color: white;
  font-size: 2.5em; /* Økt font-størrelsen for tittelen */
  font-weight: bold; /* Gjør tittelen fetere */
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Legger til en tekstskygge for bedre lesbarhet */
}

.title-link h1 {
  margin: 0;
}

/* Media query for mindre skjermer */
@media (max-width: 768px) {
  .header {
    height: auto; /* Justerer høyden på headeren */
    padding: 1em;
  }

  .header-content {
    flex-direction: column; /* Vertikal layout for mindre skjermer */
    text-align: center; /* Sentrer teksten */
  }

  .logo {
    height: 60px; /* Juster høyden på logoen for mindre skjermer */
    margin-bottom: 1em; /* Legg til mellomrom under logoen */
  }

  .title {
    font-size: 1.5em; /* Mindre font-størrelse for tittelen på mindre skjermer */
  }
}
