.main-content {
    padding: 20px;
  }
  
  .news-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .news-item h3 {
    margin-top: 0;
  }
  
  .news-date {
    font-size: 0.9em;
    color: #888;
  }
  
  .news-item-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .news-item-actions button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .news-item-actions button:hover {
    background-color: #0056b3;
  }
  