.navbar {
  background-color: #333333;
  padding: 1em 2em;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box; /* Inkluder padding i bredden */
}

.navbar-list {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin: 0 1em;
}

.navbar-link {
  text-decoration: none;
  color: white;
  font-size: 1.2em;
  position: relative;
  padding: 0.5em 1em;
  transition: color 0.3s;
}

.navbar-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76, 175, 80, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
}

.navbar-link:hover::after {
  opacity: 1;
}

.navbar-link:hover {
  color: #6686cc;
}

.hamburger {
  display: none;
  cursor: pointer;
  padding: 0.5em;
  z-index: 10;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.hamburger.active div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active div:nth-child(2) {
  opacity: 0;
}

.hamburger.active div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .hamburger {
    display: block;
    position: relative;
  }

  .navbar-list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: #333333;
    position: absolute;
    top: 60px;
    left: 0;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9;
  }

  .navbar-list.active {
    display: flex;
    opacity: 1;
  }

  .navbar-item {
    margin: 0.5em 0;
    width: 100%;
  }

  .navbar-link {
    padding: 1em 2em;
    width: 100%;
    text-align: left;
  }
}
