.sidebar {
  background-color: #fafafa;
  border-left: 1px solid #ddd;
  padding: 1.5em;
  width: 450px;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Segoe UI', sans-serif;
}

.sidebar .widget {
  padding: 1em;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  margin-bottom: 1.5em;
  word-break: break-word;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 1.4em;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.3em;
  margin-bottom: 0.8em;
}

.sidebar p {
  margin: 0;
  color: #555;
  line-height: 1.5;
}

.diesel-widget-container,
.tide-widget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.diesel-widget,
.tide-widget {
  text-align: center;
}

.diesel-widget-price,
.tide-widget-status {
  font-size: 1.2em;
  font-weight: 600;
  color: #222;
  margin-bottom: 0.5em;
}

.diesel-price-input {
  width: 120px;
  padding: 8px;
  font-size: 1em;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.diesel-save-button,
.diesel-cancel-button,
.diesel-edit-button {
  background-color: #00796b;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 6px 12px;
  font-size: 0.9em;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.diesel-save-button:hover,
.diesel-cancel-button:hover,
.diesel-edit-button:hover {
  background-color: #005f56;
}

.tide-widget-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.tide-widget-next {
  font-size: 0.95em;
  color: #444;
}

.tide-widget-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    border-left: none;
    padding: 1em;
  }

  .sidebar .widget {
    padding: 1em;
  }
}

.weather-widget-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5em;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5em;
}

.weather-parameter-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.weather-parameter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.weather-icon-and-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.1em;
  color: #333;
}

.weather-icon-and-label i {
  margin-right: 0.5rem;
  color: #00796b;
}

.weather-data-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.weather-data-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.weather-data-value {
  font-size: 1.2em;
  color: #00796b;
}

.weather-wind-direction-arrow {
  font-size: 2em;
  color: #00796b;
  transition: transform 0.5s ease;
}

.weather-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.5rem;
  color: #555;
}

.weather-loading i {
  font-size: 2rem;
  margin-bottom: 1rem;
}
