.parameter {
  display: flex;
  flex-direction: column;
}

.icon-and-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon-and-label i {
  margin-right: 0.5rem;
}

.data-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.data-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.currwind-row {
  margin-bottom: -1.0rem;
}

.currwind-parameter .icon-and-label {
  margin-bottom: 0.5rem;
}

.currwind-parameter .data-grid {
  margin-top: -0.5rem;
}

.location {
  font-weight: normal;
  flex-shrink: 0;
  width: 60px;
}

.label {
  font-weight: normal;
}

.wind-container {
  display: flex;
  align-items: center;
}

.temperature, .barometer, .humidity, .rain, .currwind, .gustwind {
  display: flex;
  align-items: center;
}

.wind-icon {
  margin-right: 1.0rem;
  flex-shrink: 0;
}

.description {
  font-weight: bold;
  margin-right: 0.5rem;
  white-space: nowrap;
}

.data-value {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.old-data {
  color: red;
}

.value {
  flex-shrink: 0;
  white-space: nowrap;
}

.parameter-box {
  display: flex;
  flex-direction: column;
}

.parameter-box table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.parameter-box th, .parameter-box td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parameter-box th {
  background-color: #f2f2f2;
}
