.tide-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .tide-container h3 {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    font-size: 1.5em;
  }
  
  .tide-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .tide-item:hover {
    transform: scale(1.05);
  }
  
  .tide-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .tide-info {
    text-align: center;
  }
  
  .tide-time {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .tide-value {
    display: block;
    font-size: 1em;
    color: #555;
  }
  