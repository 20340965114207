.alerts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    margin: 20px 0;
  }
  
  .alert-box {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    text-align: left;
    font-family: var(--font-body);
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 3px;
    box-shadow: 2px 2px 6px rgb(255 255 255 / 25%);
    overflow: hidden;
    cursor: pointer;
  }
  
  .yellow-alert {
    border: 3px solid yellow;
    background-color: rgba(255, 255, 0, 0.08);
  }
  
  .orange-alert {
    border: 3px solid orange;
    background-color: rgba(255, 165, 0, 0.08);
  }
  
  .red-alert {
    border: 3px solid red;
    background-color: rgba(255, 0, 0, 0.08);
  }
  
  .alert-header {
    margin: 0;
    display: flex;
    align-items: center;
    line-height: var(--line-height-header, 1.2);
  }
  
  .alert-header i {
    margin-right: 10px;
  }
  
  .alert-header .toggle-text {
    margin-left: auto;
    font-weight: normal;
    font-size: 1.0rem;
  }
  
  .alert-details {
    margin-top: 5px;
    line-height: var(--line-height-content, 1.4);
  }
  
  .alert-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  
  .alert-content-enter {
    max-height: 0;
    opacity: 0;
  }
  
  .alert-content-enter-active {
    max-height: 500px; /* or any max-height large enough to contain the content */
    opacity: 1;
  }
  
  .alert-content-enter-done {
    max-height: 500px;
    opacity: 1;
  }
  
  .alert-content-exit {
    max-height: 500px; /* or any max-height large enough to contain the content */
    opacity: 1;
  }
  
  .alert-content-exit-active {
    max-height: 0;
    opacity: 0;
  }
  
  .alert-content-exit-done {
    max-height: 0;
    opacity: 0;
  }
  
  @media (max-width: 600px) {
    .alert-box {
      width: 90%;
    }
  }
  