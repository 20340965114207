.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 0rem;
  border-radius: 5px;
  position: relative;
  width: 100%;
  max-width: 600px;
}

.close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.trend-data {
  margin-top: 1rem;
}

.trend-popup-title {
  text-align: center;
  margin-right: 20px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Match the main content font */
  font-size: 1.5em; /* Match the main content font size */
}
