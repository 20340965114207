.main-content {
  margin: 0 auto;
  padding: 2em;
  max-width: 1200px;
  width: 100%; /* Legg til width: 100% */
  box-sizing: border-box; /* Inkluder padding i bredden */
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 1em;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

footer {
  background-color: #333333;
  color: white;
  text-align: center;
  padding: 1em 0;
  width: 100%;
}

@media (max-width: 768px) {
  .main-content {
    padding: 1em;
    width: 100%; /* Sørg for at bredden er 100% på mobil */
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 0.5em 0;
  }
}

.main-content {
  margin: 0 auto;
  padding: 2em;
  max-width: 1200px;
  width: 100%; /* Legg til width: 100% */
  box-sizing: border-box; /* Inkluder padding i bredden */
}

@media (max-width: 768px) {
  .main-content {
    padding: 1em;
    width: 100%; /* Sørg for at bredden er 100% på mobil */
  }
}
